.helper-btn-container {
    display: flex;
    position: absolute;
    right: calc(var(--screen-width) * 0.01);
    top: calc(var(--screen-height) * 0.4);
}

.helper-btns {
    display: flex;
    height: calc(var(--screen-height) * 0.15);
    justify-content: center;
}
