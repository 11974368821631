/* ===== pop up ===== */
.popup-bg {
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../backgrounds/Grayed_Out_Rectangle.gif');
    background-size: cover;
    background-position: center;
    position: absolute;
    background-repeat: no-repeat;
    top: 0;
    left: 0;
    width: var(--screen-width);
    height: var(--screen-height);
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}
.popup-container {
    position: relative;
    margin-top: auto;
    margin-bottom: auto;
}

.popup-container {
    position: relative;
    margin-top: auto;
    margin-bottom: auto;
}

.popup-rectangle {
    position: absolute;
    object-fit: contain;
    margin-top: auto;
    margin-bottom: auto;
    background-color: #a7e4e1;
    border: calc(var(--screen-width) * 0.01) solid black;
    border-radius: calc(var(--screen-width) * 0.04);
    width: calc(var(--screen-width) * 0.57);
    height: calc(var(--screen-height) * 0.47);
    top: calc(var(--screen-height) * 0.19);
}

.popup-title {
    color: black;
    font-family: 'Fredoka', sans-serif;
    font-weight: 600;
    text-align: center;
    font-size: calc(var(--screen-width) * 0.05);
    margin: calc(var(--screen-height) * 0.08) auto calc(var(--screen-height) * 0.04) auto;
}

.popup-text {
    color: black;
    font-family: 'Balsamiq Sans', sans-serif;
    font-weight: 400;
    text-align: center;
    font-size: calc(var(--screen-width) * 0.03);
    margin: calc(var(--screen-height) * 0.01) calc(var(--screen-width) * 0.03) auto calc(var(--screen-width) * 0.03);
}

.continue-btn {
    position: absolute;
    top: calc(var(--screen-height) * 0.71);
    height: calc(var(--screen-height) * 0.15);
}

/* for incorrect popup */
.popup-bg-2 {
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
    background-size: cover;
    background-position: center;
    position: absolute;
    background-repeat: no-repeat;
    top: 0;
    left: 0;
    width: var(--screen-width);
    height: var(--screen-height);
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.popup-title-2 {
    color: black;
    font-family: 'Fredoka', sans-serif;
    font-weight: 600;
    text-align: center;
    font-size: calc(var(--screen-width) * 0.07);
    margin-top: calc(var(--screen-height) * 0.15);
}

.popup-title-2-sm {
    margin-top: calc(var(--screen-height) * 0.1);
}

/* for general popup */
.general-popup-rectangle {
    position: absolute;
    object-fit: contain;
    margin-top: auto;
    margin-bottom: auto;
    background-color: #a7e4e1;
    border: calc(var(--screen-width) * 0.01) solid black;
    border-radius: calc(var(--screen-width) * 0.04);
    width: calc(var(--screen-width) * 0.57);
    height: calc(var(--screen-height) * 0.50);
    top: calc(var(--screen-height) * 0.30);
}

.popup-title-3 {
    color: black;
    font-family: 'Fredoka', sans-serif;
    font-weight: 600;
    text-align: center;
    font-size: calc(var(--screen-width) * 0.06);
    margin-top: calc(var(--screen-height) * 0.12);
}