/* 
green title rectangle styles 
used for different sty 
*/
.green-title-rectangle {
    display: flex;
    margin: calc(var(--screen-width) * 0.02) auto calc(var(--screen-height) * 0.01);
    background-color: #C2D2A3;
    border: calc(var(--screen-width) * 0.005) solid black;
    border-radius: calc(var(--screen-width) * 0.01);
    justify-content: center;
    color: black;
    font-family: 'Fredoka', sans-serif;
    font-weight: 600;
    text-align: center;
    font-size: calc(var(--screen-width) * 0.03);
    width: fit-content; 
    height: 7%; 
  };

.green-bar-icon {
    object-fit: contain; 
    margin: auto calc(var(--screen-width) * 0.01) auto 0vw;
}

.green-bar-title {
    margin: auto calc(var(--screen-width) * 0.01); 
    display: flex; 
}

