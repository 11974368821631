.scrollable-credits {
  height: calc(var(--screen-height) * 0.57);
  width: calc(var(--screen-width) * 0.75);
  background-color: white;
  border: calc(var(--screen-width) * 0.007) solid black;
  border-radius: calc(var(--screen-width) * 0.01);
  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-color: #C2D2A3 transparent;
  white-space: nowrap;
  display: block;
  margin: auto;
}

.credit-container {
  margin-bottom: calc(var(--screen-height) * 0.05);
}

.credit-title {
  color: black;
  font-family: 'Balsamiq Sans', sans-serif;
  font-weight: 500;
  text-align: center;
  font-size: 4vw;
  justify-content: center;
  margin-top: 1.2em;
  text-decoration: underline;
}

.credit-names {
  color: black;
  font-family: 'Balsamiq Sans', sans-serif;
  font-weight: 500;
  text-align: center;
  font-size: 3vw;
  margin-top: 1em;
}

.credit-back-btn {
  position: absolute;
  background-color: transparent;
  border: none;
  width: 15vw;
  height: 20vh;
  top: 80vh;
}