@import url('https://fonts.googleapis.com/css2?family=Balsamiq+Sans&display=swap');

:root {
  --base-width: 1280;
  --base-height: 832;
}

.drag-in-container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  font-family: 'Balsamiq Sans', cursive;
}

.draggable-wrapper {
  position: absolute;
  z-index: 2;
  touch-action: none;
  cursor: grab;
}

.draggable-wrapper img {
  position: absolute;
  pointer-events: auto;
  user-select: none;
}

.draggable-wrapper:active {
  cursor: grabbing;
  z-index: 1000;
}

.rock-dropzone {
  position: absolute;
  z-index: 1;
}

.rock-dropzone img {
  position: relative;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

/* Add visual feedback for valid drop target */
.rock-dropzone.can-drop {
  background-color: rgba(0, 255, 0, 0.1);
}

/* Animation for when cloth is dropped */
@keyframes fadeOut {
  from { opacity: 1; }
  to { opacity: 0; }
}

.draggable-wrapper.dropped {
  animation: fadeOut 0.3s ease forwards;
}
