.texttop-mc-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: var(--screen-width);
  height: var(--screen-height);
  text-align: center;
  margin: calc(var(--screen-height) * -0.07) 0 0 0;
  /* Move the container up */
  padding: 0;
}

.texttop-question-rectangle {
  display: flex;
  background-color: #a7e4e1;
  border: calc(var(--screen-width) * 0.005) solid black;
  border-radius: calc(var(--screen-width) * 0.01);
  justify-content: center;
  align-items: center;
  margin: 0 calc(var(--screen-width) * 0.05) calc(var(--screen-height) * 0.04);
  width: calc(var(--screen-width) * 0.5);
  padding: calc(var(--screen-height) * 0.01) calc(var(--screen-width) * 0.04);
}

.texttop-question-text {
  color: black;
  font-family: 'Balsamiq Sans', sans-serif;
  font-weight: 400;
  text-align: center;
  font-size: calc(var(--screen-width) * 0.028);
}

.texttop-mc-content {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  margin-top: calc(var(--screen-height) * 0.05);
}

.texttop-picture-btn {
  width: calc(var(--screen-width) * 0.3);
  height: calc(var(--screen-height) * 0.35);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: calc(var(--screen-height) * 0.03);
  margin-top: calc(var(--screen-height) * -0.1);
  font-size: calc(var(--screen-width) * 0.032);
  /* Adjust size as needed */
  font-family: 'Balsamiq Sans';
  font-weight: 400;
  border-radius: calc(var(--screen-width) * 0.05);
  border-width: calc(var(--screen-width) * 0.005);
  background-color: aliceblue;
}

.texttop-picture-btn img {
  width: 100%;
  height: 70%;
  object-fit: contain;
  order: 2;
  /* Move image below text */
}

.texttop-picture-btn:hover {
  background-color: #a7e4e1;
}

.inactive-btn {
  pointer-events: none;
  opacity: 0.5;
}