/* Written by Linh */

.background {
    background-size: cover !important;
    background-repeat: no-repeat !important;
    height: var(--screen-height);
    width: var(--screen-width);
}

.default-bg {
    background: url("../backgrounds/BG_Default.png");
}

.high-grass-bg {
    background: url("../backgrounds/BG_Higher_Grass.png");
}

.volcano-bg {
    background: url("../backgrounds/BG_Volcano.png");
}