.words-bg {
    position: relative;
    display: flex;
    top: calc(var(--screen-height) * 0.18);
    left: calc(var(--screen-width) * 0.1);
    width: calc(var(--screen-width) * 0.38);
    height: calc(var(--screen-height) * 0.4);
    background-color: white;
    border: 0.3vw solid black;
    border-radius: 0.5vw;
    align-items: center;
}

.words-container {
    display: flex; 
    flex-wrap: wrap; 
    justify-content: center; 
    margin: 0.8vw;
}

.words-selector {
    display: inline-block;
    text-align: center;
    background-color: #A7E4E1;
    font-family: 'Balsamiq Sans';
    font-weight: 400;
    font-size: calc(var(--screen-width) * 0.03);
    border: 0.25vw solid black;
    border-radius: 0.5vw;
    margin: 0.4vw;
    padding: calc(var(--screen-height) * 0.01) calc(var(--screen-width) * 0.01);
}

.record-word-btn {
    position: absolute;
    width: calc(var(--screen-width) * 0.08);
    top: calc(var(--screen-height) * 0.58);
    left: calc(var(--screen-width) * 0.7);
}