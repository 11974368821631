/* Lesson page Styles  */
.lesson-page-body {
    display: flex; 
    flex-direction: column;
    width: var(--screen-width); 
    height: var(--screen-height); 
}

.lesson-title {
    display: flex;
}

.lesson-play-button {
    width: auto;
    height: auto;
    background-color: #A7E4E1; 
    border: calc(var(--screen-width) * 0.007) solid black; 
    border-radius: calc(var(--screen-width) * 0.01); 
    color: black; 
    font-family: 'Fredoka', sans-serif; 
    font-size: calc(var(--screen-width) * 0.05); 
    font-weight: 500;
    margin: 0 auto;
    padding: calc(var(--screen-height) * 0.01) calc(var(--screen-width) * 0.025);

    &:hover {
        background-color: #587E86; 
    }
}

/* Module, lesson, unit page Styles  */
.scrollable-page-body {
    display: flex; 
    flex-direction: column;
    width: var(--screen-width); 
    height: var(--screen-height); 
}

.backpack-scrollable-page-body { 
    height:calc(var(--screen-height) * 0.6); 
}

.scrollable-item-selection {
    height: calc(var(--screen-height) * 0.6); 
    width: calc(var(--screen-width) * 0.75); 
    background-color: white;
    border: calc(var(--screen-width) * 0.01) solid black; 
    border-radius: calc(var(--screen-width) * 0.02); 
    overflow-y: hidden;
    overflow-x: scroll;
    scrollbar-color: #C2D2A3 transparent; 
    white-space: nowrap; 
    display: flex;
    margin: calc(var(--screen-width) * 0.01) auto;
}

.green-back-button {
    position: absolute; 
    cursor: pointer; 
    width: calc(var(--screen-width) * 0.07); 
    height: auto; 
    left: calc(var(--screen-width) * 0.03); 
    top: calc(var(--screen-height) * 0.85); 
}

