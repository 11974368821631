/* ===== Vertical right multiple choice styles ===== */

.mc-container {
    display: flex; 
    position: absolute;
    margin-left: calc(var(--screen-width) * 0.6);
    margin-top: calc(var(--screen-width) * 0.1);
    width: calc(var(--screen-width) * 0.3);
    /* width: auto; */
    text-align: center; 
    flex-direction: column; 
}

.mc-container-half {
    margin-left: calc(var(--screen-width) * 0.5);
    margin-top: calc(var(--screen-width) * 0.11);
    width: calc(var(--screen-width) * 0.45);
}

.mc-container-third {
    margin-left: calc(var(--screen-width) * 0.7);
    margin-top: calc(var(--screen-width) * 0.15);
    /* width: calc(var(--screen-width) * 0.2); */
    width: auto;
}

.mc-btn {
    display: block;
    padding: calc(var(--screen-width) * 0.02) calc(var(--screen-width) * 0.03);
    border-radius: calc(var(--screen-width) * 0.05);
    border-width: calc(var(--screen-width) * 0.005);
    background-color: aliceblue;
    font-family: 'Balsamiq Sans';
    font-weight: 400;
    font-size: calc(var(--screen-width) * 0.03);
    margin-bottom: calc(var(--screen-height) * 0.02);

    &:hover {
        background-color: #A7E4E1;
    }
}

.es-mc-btn {
    font-size: calc(var(--screen-width) * 0.026) !important;
}


/* ===== Horizonal multiple choice with pictures ===== */

.horizontal-mc-container {
    display: flex; 
    position: absolute;
    flex-direction: column; 
    justify-content: center;
    align-items: center;
    width: var(--screen-width); 
}

/* Blue Rectangle */
.question-rectangle {
    display: flex;
    background-color: #a7e4e1;
    border: calc(var(--screen-width) * 0.005) solid black;
    border-radius: calc(var(--screen-width) * 0.01);
    justify-content: center;
    align-items: center;
    margin-top: calc(var(--screen-height) * 0.19);
    padding: calc(var(--screen-height) * 0.01) calc(var(--screen-width) * 0.05); 
    height: calc(var(--screen-width) * 0.065);
    max-width: calc(var(--screen-width) * 0.65);
}

.question-text {
    color: black;
    font-family: 'Balsamiq Sans', sans-serif;
    font-weight: 400;
    text-align: center;
    font-size: calc(var(--screen-width) * 0.028);
}

.picture-btn-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: calc(var(--screen-width) * 0.09);
    margin-top: calc(var(--screen-height) * 0.06);
}

.picture-btn {
    display: grid;
    padding: calc(var(--screen-width) * 0.005) calc(var(--screen-width) * 0.005);
    border-radius: calc(var(--screen-width) * 0.05);
    border: calc(var(--screen-width) * 0.005) solid black;
    background-color: aliceblue;
    width: calc(var(--screen-width) * 0.23);
    height: calc(var(--screen-height) * 0.2885);
    font-family: 'Fredoka';
    font-weight: 600;
    font-size: calc(var(--screen-width) * 0.02);
    grid-template-rows: 1fr auto;
    align-items: center;
    text-overflow: ellipsis;

    &:hover {
        background-color: #A7E4E1;
    }
}

.picture-btn img {
    max-width: 100%;
    max-height: 100%;
    justify-items: center;
    align-items: center;
}

.picture-container {
    width: auto;
    height: calc(var(--screen-height) * 0.1923);
    object-fit: contain;
    display: flex;
    justify-content: center;
    text-align: center;
    margin-top: 0;
}

/* ===== Left right multiple choice ===== */
.left-right-question-container {
    display: flex;
    position: absolute;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: var(--screen-width); 
    height: calc(var(--screen-height) * 0.8); 
}

.higher-question-rectangle {
    display: flex;
    background-color: #a7e4e1;
    border: calc(var(--screen-width) * 0.005) solid black;
    border-radius: calc(var(--screen-width) * 0.02);
    justify-content: center;
    align-items: center;
    padding: calc(var(--screen-height) * 0.030) calc(var(--screen-width) * 0.02);
}

.left-right-container {
    display: flex;
    justify-content: space-between;
    width: 90%; 
    margin: calc(var(--screen-height) * 0.1) calc(var(--screen-width) * 0.05);
}

.left-right-display-container {
    display: flex;
    position: absolute;
    justify-content: space-between;
    width: calc(var(--screen-width) * 0.9); 
    margin: calc(var(--screen-height) * 0.4) calc(var(--screen-width) * 0.05);
}

.left-right-btn {
    display: block;
    padding: calc(var(--screen-height) * 0.01) calc(var(--screen-width) * 0.01);
    border-radius: calc(var(--screen-width) * 0.04);
    border-width: calc(var(--screen-width) * 0.005);
    background-color: aliceblue;
    font-family: 'Balsamiq Sans';
    font-weight: 600;
    font-size: calc(var(--screen-width) * 0.035);
    width: calc(var(--screen-width) * 0.2148);
    height: calc(var(--screen-height) * 0.1863);

    &:hover {
        background-color: #A7E4E1;
    }
}

.correct-btn {
    background-color: #A7E4E1;
}

.inactive-btn {
    pointer-events: none;
    cursor: default;
    opacity: 0.8;
}

.inactive-btn:hover {
    background-color: inherit;
}

.display-btn {
    pointer-events: none;
    cursor: default;
}