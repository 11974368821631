/* ===== Drag and Drop styles ===== */

.dd-component {
    --cell-size: calc(var(--screen-height) * 0.15);
    --drop-width: calc(var(--screen-width) * 0.11);
    --drop-height: calc(var(--screen-height) * 0.15);
}

.dd-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: calc(var(--screen-height) * 1);
    box-sizing: border-box;
    overflow: hidden;
    font-family: 'Arial', sans-serif;
}

.dd-draggable-items {
    display: flex;
    flex-wrap: wrap;
    gap: calc(var(--screen-height) * 0.012);
    justify-content: center;
    width: calc(var(--screen-width) * 0.3);
}

.dd-draggable-item {
    width: calc(var(--cell-size) * 0.5);
    height: var(--cell-size);
    border-radius: 0;
    cursor: grab;
    border: none;
    outline: none;
    box-shadow: none;
}

.dd-drop-zone {
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(var(--screen-width) * 0.078);
    height: calc(var(--screen-height) * 0.12);
    border-radius: 0;
}

.dd-dropped-item-container, .dd-empty-slot {
    width: calc(var(--screen-width) * 0.078);
    height: calc(var(--screen-height) * 0.12);
    border: calc(var(--screen-width) * 0.00078) solid #eee;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0;
}

.dd-draggable-item.dd-hidden {
    visibility: hidden;
}

.dd-layout {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    position: relative;
}

.dd-top-images {
    display: flex;
    justify-content: center;
    gap: calc(var(--screen-width) * 0.02);
    position: absolute;
    top: calc(var(--screen-height) * 0.02);
    left: 0;
    right: 0;
}

.dd-main-content {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: calc(var(--screen-height) * 0.6);
    margin-top: calc(var(--screen-height) * 0.15);
    position: relative;
}

.dd-left-images, .dd-right-images {
    display: flex;
    flex-direction: column;
    gap: calc(var(--screen-height) * 0.02);
    justify-content: center;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.dd-left-images {
    right: calc(50% + var(--screen-width) * 0.225);
}

.dd-right-images {
    left: calc(50% + var(--screen-width) * 0.225);
}

.dd-grid {
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  height: calc(var(--screen-height) * 0.4);
  width: calc(var(--screen-width) * 0.3);
  column-gap: calc(var(--screen-height) * 0.07);
}

.dd-grid-4 {
  grid-template-columns: repeat(2, 1fr);
}

.dd-grid-8 {
  grid-template-columns: repeat(4, 1fr);
}

.dd-droppable-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    box-sizing: border-box;
}

.dd-droppable-zone {
  height: calc(var(--screen-width) * 0.12);
  margin-top: calc(var(--screen-height) * -0.02);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.dd-grid-4 .dd-droppable-zone {
  width: calc(var(--screen-width) * 0.12);
}

.dd-grid-8 .dd-droppable-zone {
  width: calc(var(--screen-width) * 0.06);
  height: calc(var(--screen-width) * 0.06);
  margin-left: calc(var(--screen-width) * -0.015); /* Move left */
  margin-top: calc(var(--screen-width) * 0.015);   /* Move down */
  box-sizing: border-box; /* Ensure padding doesn't affect overall size */
}



.dd-draggable-item, .dd-dropped-item, .dd-placeholder-item {
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(var(--screen-width) * 0.19);
    height: calc(var(--screen-width) * 0.12);
    box-sizing: border-box;
    background-color: transparent;
    border: none;
}

.dd-draggable-item img, .dd-dropped-item img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
}

.dd-draggable-layer {
    position: relative;
    z-index: 100;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.dd-drag-overlay {
    z-index: 1000;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
}

.dd-drag-overlay .dd-draggable-item,
.dd-drag-overlay {
    transform: scale(1.05);
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    background-color: transparent;
}

.dd-drop-placeholder {
    color: #8B4513;
    font-size: calc(var(--screen-width) * 0.011);
}

.dd-draggable-wrapper {
    position: relative;
    z-index: 1;
    touch-action: none;
}

.dd-draggable-layer > * {
    pointer-events: auto;
}

.dd-placeholder-item {
    width: calc(var(--screen-width) * 0.19);
    height: calc(var(--screen-width) * 0.12);
    visibility: hidden;
}

.dd-droppable-container:hover,
.dd-droppable-container:active,
.dd-droppable-container:focus {
    background-color: transparent !important;
    border: none !important;
    outline: none !important;
}

.dd-draggable-item img,
.dd-drag-overlay img {
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
}

/* Add this new class */
.dd-grid-8 .dd-dropped-item img,
.dd-grid-8 .dd-draggable-item img {
    width: calc(var(--screen-width) * 0.09);  /* Adjust this value as needed */
    height: calc(var(--screen-width) * 0.09);  /* Adjust this value as needed */
}
