.tutorial-toc {
    height: calc(var(--screen-height) * 0.47);
    margin: calc(var(--screen-height) * 0.03) auto;
}

.scrollable-topic-list {
    margin: calc(var(--screen-height) * 0.08) auto;
    height: calc(var(--screen-height) * 0.6);
    width: calc(var(--screen-width) * 0.8);
    background-color: white;
    border: calc(var(--screen-width) * 0.007) solid black;
    border-radius: calc(var(--screen-width) * 0.005);
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-color: #C2D2A3 transparent;
    direction: rtl;
}

.topic-list-instance {
    display: flex;
    justify-content: left;
    height: calc(var(--screen-height) * 0.1);
    margin: calc(var(--screen-height) * 0.02) calc(var(--screen-width) * 0.04);
}

.topic-text {
    font-family: 'Fredoka', sans-serif;
    font-size: calc(var(--screen-width) * 0.04);
    font-weight: 550;
    margin-top: auto;
    margin-bottom: auto;
    white-space: nowrap;
    margin: calc(var(--screen-height) * 0) calc(var(--screen-width) * 0.02);
}

.checkbox-toc {
    width: calc(var(--screen-width) * 0.05);
    height: auto;
    left: calc(var(--screen-width) * 0.01);
    top: calc(var(--screen-height) * 0.15);
}

.dots-spacing {
    width: 100%;
    background: radial-gradient(circle at center calc(var(--screen-height) * 0.06), #999 8%, transparent 8%);
    background-size: calc(var(--screen-height) * 0.02);
    background-repeat: repeat-x;
}