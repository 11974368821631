/* styles for bold green text with black outline */
.title-text-style {
    display: flex;
    position: relative;
    font-family: 'Fredoka', sans-serif; 
    font-size: calc(var(--screen-height) * 0.12);
    font-weight: 600;
    text-align: center;
    align-items: center;
}

.lesson-title-outer-container {
    display: flex;
    justify-content: center; 
    width: calc(var(--screen-width) * 0.7);
    height: calc(var(--screen-height) * 0.5);  
    margin: calc(var(--screen-height)* 0.15) auto calc(var(--screen-height)* 0.05);
}

.title-text-outer-container {
    display: flex;
    justify-content: center; 
    width: var(--screen-width); 
    padding: calc(var(--screen-height) * 0.07) 0 0;
}

.backpack-title-text-outer-container {
    display: flex;
    justify-content: center; 
    width: var(--screen-width); 
    padding: calc(var(--screen-height) * 0.03) 0 0;
}

.title-text-style .black-text {
    position: absolute;
    color: black; 
}

.title-text-style .yellow-outline {
    color: #FFC702;
    -webkit-text-stroke: calc(var(--screen-height) * 0.03) #FFC702; 
}

.title-text-style .green-text {
    position: absolute;
    color: #C2D2A3; 
}

.title-text-style .black-outline {
    color: white;
    -webkit-text-stroke: calc(var(--screen-width) * 0.018) black; 
}

