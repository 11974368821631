/* Paragraph Text */
.paragraph-text {
    position: flex;
    font-family: 'Balsamiq Sans', sans-serif;
    text-align: center;
    /* Adjust based on AspectRatioCheck() */
    font-size: calc(var(--screen-width) * 0.03);
}

.paragraph-background {
    border: calc(var(--screen-width) * 0.005) solid black; 
    background-color: #a7e4e1;
    padding: calc(var(--screen-width) * 0.01);
    margin: auto;
    max-width: fit-content;
    border-radius: calc(var(--screen-width) * 0.013);
}

.paragraph-parent {
    margin: calc(var(--screen-width) * 0.12);

    
}