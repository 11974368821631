.red-text {
    color: #FF0000;
}

.yellow-text {
    color: #FFC702;
}

.green-text {
    color: #649712;
}

.blue-text {
    color: #0038FF;
}

.underline-text {
    text-decoration: underline;
}