/* Footer */

.footer {
    background-color: white;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: calc(var(--screen-height) * 0.2);
    text-align: center;
    display: flex;
    justify-content: space-between;
}

/* Left Button */
.button-left {
    position: absolute;
    cursor: pointer;
    width: calc(var(--screen-width) * 0.08);
    height: auto;
    left: calc(var(--screen-width) * 0.02);
    bottom: calc(var(--screen-height) * 0.04);
}

/* Right Button */
.button-right {
    position: absolute;
    cursor: pointer;
    width: calc(var(--screen-width) * 0.08);
    height: auto;
    right: calc(var(--screen-width) * 0.02);
    bottom: calc(var(--screen-height) * 0.04);
}

/* Blue Rectangle */
.blue-rectangle {
    position: absolute;
    min-width: calc(var(--screen-width) * 0.2);
    height: calc(var(--screen-height) * 0.07);
    background-color: #a7e4e1;
    left: calc(var(--screen-width) * 0.1);
    top: calc(var(--screen-height) * -0.05);
    border: calc(var(--screen-width) * 0.005) solid black;
    border-radius: calc(var(--screen-width) * 0.01);
    padding-left: calc(var(--screen-width) * 0.01);
    padding-right: calc(var(--screen-width) * 0.01);
    display: flex;
    justify-content: center;
    align-items: center;
}

.blue-rectangle-text {
    color: black;
    font-family: 'Fredoka', sans-serif;
    font-weight: 600;
    text-align: center;
    font-size: calc(var(--screen-width) * 0.04);
}


/* Exit Rectangle */
.exit-rectangle {
    cursor: pointer;
    height: 40%;
    margin: calc(var(--screen-height) * 0.045) calc(var(--screen-width) * 0.04);
    padding: calc(var(--screen-width) * 0.005) calc(var(--screen-width) * 0.01);
    background-color: #A7E4E1;
    border: calc(var(--screen-width) * 0.005) solid black;
    border-radius: calc(var(--screen-width) * 0.01); 
    flex-shrink: 0; 
    &:hover {
        background-color: #587E86; 
    }
}

.exit-rectangle-text {
    color: black;
    font-family: 'Fredoka', sans-serif;
    font-weight: 600;
    text-align: center;
    font-size: calc(var(--screen-width) * 0.035);
}

/* Main Text */
.main-text {
    position: absolute;
    font-family: 'Balsamiq Sans', sans-serif;
    text-align: left;
    left: 12%;
    top: 25%;
    width: 75%;
    /* Adjust based on AspectRatioCheck() */
    font-size: calc(var(--screen-width) * 0.033);
}

/* Ending Text */
.ending-text {
    font-family: 'Balsamiq Sans', sans-serif;
    text-align: left;
    min-width:0; 
    font-size: calc(var(--screen-width) * 0.033);
    margin-left: calc(var(--screen-width) * 0.12);
    margin-top: calc(var(--screen-height) * 0.05);
}

/* Hint Pop-up */
.hint-popup {
    position: absolute;
    width: 33vw;
    height: 30vh;
    background-color: #a7e4e1;
    left: 32vw;
    top: -55vh;
    border: 0.8vw solid black;
    border-radius: 3vw;
    color: black;
    font-family: 'Fredoka', sans-serif;
    font-size: 3.3vw;
    /* Adjust based on AspectRatioCheck() */
    font-weight: 500;
    padding: 3.2vw;
    visibility: hidden;
    transition: visibility 0s, opacity 0.5s linear;
}

/* Hint Button */
.hint-button {
    position: absolute;
    background-color: #a7e4e1;
    left: 10vw;
    /* Adjust based on AspectRatioCheck() */
    top: -5.5vh;
    border: 0.5vw solid black;
    border-radius: 9vw;
    color: black;
    font-family: 'Fredoka', sans-serif;
    font-size: 4.5vw;
    /* Adjust based on AspectRatioCheck() */
    font-weight: 500;
    padding-left: 1.8vw;
    padding-right: 1.8vw;
    padding-top: 0.05vh;
    padding-bottom: 0.05vh;
    cursor: pointer;
}

/* Highlighted Effect */
.highlighted {
    -webkit-filter: drop-shadow(3px 3px 0 #FFC702) drop-shadow(-3px -3px 0 #FFC702) drop-shadow(-3px 3px 0 #FFC702) drop-shadow(3px -3px 0 #FFC702) drop-shadow(3px 0px 0 #FFC702) drop-shadow(-3px 0px 0 #FFC702) drop-shadow(0px 3px 0 #FFC702) drop-shadow(0px -3px 0 #FFC702);
    filter: drop-shadow(3px 3px 0 #FFC702) drop-shadow(-3px -3px 0 #FFC702) drop-shadow(-3px 3px 0 #FFC702) drop-shadow(3px -3px 0 #FFC702) drop-shadow(3px 0px 0 #FFC702) drop-shadow(-3px 0px 0 #FFC702) drop-shadow(0px 3px 0 #FFC702) drop-shadow(0px -3px 0 #FFC702);
}