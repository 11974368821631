html,
body {
    margin: 0;
    padding: 0;
    height: 100%;
    overflow: hidden;
}

.mitos-title {
    display: flex;
    position: relative;
    justify-content: center;
    margin: 5vh auto;
    width: 70vw;
}

.section-container {
    margin: 0 auto;
    /* margin-top: 5vh; */
    font-family: 'Comic Sans MS';
}

.input-container {
    position: relative;
    margin: 0 auto;
    margin-bottom: 1vh;
    width: 28vw;
    min-width: 200px;
    height: 4vh;
    min-height: 50px;
}

.signup-form{
    overflow-y: auto;
}

.col-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 4vh;
    margin: 3% 10%;
}

.col-container>*:nth-last-child(1) {
    /* Target the last child */
    grid-column: span 2;
    /* Span both columns for spacing */
    margin: 0 auto;
}

.input {
    background-color: white;
    border-color: black;
    border-width: 5px;
    border-radius: 5px;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    font-size: 25px;
    font-family: 'Comic Sans MS';
    padding-left: 50px;
}

.red-border {
    border-color: red;
}

.input-container input::placeholder {
    font-size: 25px;
}

.icon {
    position: absolute;
    top: 25%;
    left: 10px;
    width: 30px;
    height: 30px;
}

.button-container {
    margin: 3vh auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.auth-btn {
    display: flex;
    background-color: #A7E4E1;
    border-color: black;
    border-width: calc(var(--screen-width) * 0.007);
    border-radius: calc(var(--screen-width) * 0.01);
    width: calc(var(--screen-width)* 0.2);
    height: calc(var(--screen-height) * 0.1);
    font-size: calc(var(--screen-width) * 0.035);
    font-weight: 500;
    font-family: 'Balsamiq Sans', sans-serif;
    padding: calc(var(--screen-heith) * 0.05) calc(var(--screen-width) * 0.05);
    justify-content: center;
    align-items: center;
    margin: 0 auto;

    &:hover {
        background-color: #587E86;
    }
}

.no-underline {
    text-decoration: none;
    color: #587E86;
    font-size: 20px;
}

.forgot-password {
    text-align: right;
    max-width: 400px;
    margin-top: 1vh;
}

.no-account {
    text-align: center;
    margin-top: 3vh;
    font-size: 20px;
}

.auth-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

#container-cloud {
    margin-top: 15vh;
}

.inside-container {
    width: 30%;
    padding-top: 5%;
    min-width: 200px;
    min-height: 35px;
}

.cloud {
    width: 45%;
    min-width: 300px;
}

.cloud-text {
    position: absolute;
    text-align: center;
    font-size: 2.0vw;
    font-family: 'Comic Sans MS';
    max-width: 30rem;
}

.credit-container {
    display: block;
    top: 82vh;
}

.credit-btn {
    background-color: transparent;
    border: none;
    width: 15vw;
    height: 20vh;
    top: 77vh;
    left: 3vw;
    position: absolute;
}

.credit-btn img {
    max-width: 100%;
    max-height: 100%;
    justify-items: center;
    align-items: center;
}

.credit-icon {
    position: flex;
    object-fit: contain;
}