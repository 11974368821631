/* Written by Linh */

/* Title Checkbox Style */
.title-checkbox {
    display: flex;
    position: absolute;
    padding: calc(var(--screen-height) * 0.015);
    top: 0;
    right: 1;
}

.green-rectangle {
    display: flex;
    background-color: #c2d2a3;
    border: calc(var(--screen-width) * 0.005) solid black;
    border-radius: calc(var(--screen-width) * 0.01);
    padding: calc(var(--screen-width) * 0.01);
    margin-right: calc(var(--screen-width) * 0.02);
}

/* Green Rectangle Text Style */
.green-rectangle-text {
    position: relative;
    color: black;
    font-family: 'Fredoka', sans-serif;
    font-weight: 600;
    font-size: calc(var(--screen-height) * 0.04);
}