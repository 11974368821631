.prefix-matching-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: calc(var(--screen-height) * (20/ var(--base-height)));
  padding-top: calc(var(--screen-height) * (170 / var(--base-height)));
  font-family: 'Balsamiq Sans', cursive;
}

.prefixes {
  display: flex;
  justify-content: center;
  gap: calc(var(--screen-width) * (75 / var(--base-width)));
  margin-bottom: calc(var(--screen-height) * (20 / var(--base-height)));
}

.base-words {
  display: flex;
  flex-direction: column;
  gap: calc(var(--screen-height) * (20 / var(--base-height)));
  align-items: center;
}

.prefix-item {
  width: calc(var(--screen-width) * (120 / var(--base-width)));
  height: calc(var(--screen-height) * (67 / var(--base-height)));
  background-color: #A7E4E1;
  border: calc(var(--screen-width) * (4 / var(--base-width))) solid black;
  border-radius: calc(var(--screen-width) * (5 / var(--base-width)));
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: calc(var(--screen-width) * (40 / var(--base-width)));
  cursor: move;
  user-select: none;
}

.word-container {
  display: flex;
  align-items: flex-start;
  gap: calc(var(--screen-width) * (5 / var(--base-width)));
}

.prefix-slot {
  width: calc(var(--screen-width) * (100 / var(--base-width)));
  height: calc(var(--screen-height) * (75 / var(--base-height)));
}

.prefix-placeholder {
  width: 100%;
  height: -webkit-fill-available;
  border: calc(var(--screen-width) * (4 / var(--base-width))) dashed #666;
  border-radius: calc(var(--screen-width) * (5 / var(--base-width)));
}

.base-word {
  font-size: calc(var(--screen-width) * (40 / var(--base-width)));
  padding: calc(var(--screen-width) * (10 / var(--base-width)));
  background-color: #A7E4E1;
  border: calc(var(--screen-width) * (4 / var(--base-width))) solid black;
  border-radius: calc(var(--screen-width) * (5 / var(--base-width)));
  min-width: calc(var(--screen-width) * (200 / var(--base-width)));
  text-align: center;
}

.placeholder-item {
  visibility: hidden;
  pointer-events: none;
}

.draggable-prefix-wrapper {
  position: relative;
  z-index: 1;
  touch-action: none;
}

.prefix-item.dragging {
  opacity: 0.5;
}

.prefix-item.clickable {
  cursor: pointer;
}

.prefix-item.clickable:hover {
  transform: scale(1.05);
  transition: transform 0.2s ease;
}

.base-word.combined {
  min-width: calc(var(--screen-width) * (200 / var(--base-width)));
  transition: all 0.3s ease;
}
