.timer-click-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.timer {
  display: flex;
  position: absolute;
  top: calc(var(--screen-height) * 0.15);
  left: calc(var(--screen-width) * 0.04);
  background-color: white;
  border: calc(var(--screen-width) * (6 / var(--base-width))) solid black;
  border-radius: calc(var(--screen-width) * (60 / var(--base-width)));
  padding: calc(var(--screen-height) * (15 / var(--base-height))) calc(var(--screen-width) * (25 / var(--base-width)));
  font-family: 'Balsamiq Sans', cursive;
  font-size: calc(var(--screen-width) * (60 / var(--base-width)));
  font-weight: bold;
  align-items: center;
  justify-content: space-between;
  width: calc(var(--screen-width) * (195 / var(--base-width)));
}

.timer-icon {
  position: absolute;
  left: calc(var(--screen-width) * 0.01);
  width: calc(var(--screen-width) * 0.06);
}

.papers-container {
  position: relative;
  width: 100%;
  height: 100%;
  left: calc(var(--screen-width) * 0.1);
}

.papers-container img {
  transition: transform 0.2s ease;
}

.papers-container img:hover {
  transform: scale(1.05);
}

.timer-text {
  margin-left: auto;
}
