.image-matching-container {
  display: flex;
  flex-direction: column;
  width: var(--screen-width);
  height: var(--screen-height);
  position: relative;
}

.timer-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: calc(var(--screen-height) * 0.34);
  height: calc(var(--screen-height) * 0.12);
  margin-top: calc(var(--screen-height) * 0.15);
  margin-left: calc(var(--screen-height) * 0.025);
  background: white;
  border-radius: calc(var(--screen-width) * 0.04);
  border: calc(var(--screen-width) * 0.005) solid black;
}

.bucket-dropzone {
  position: absolute;
  height: calc(var(--screen-height) * 0.4);
  left: calc(var(--screen-height) * 0.04);
  bottom: calc(var(--screen-height) * 0.24);
}

.image-draggable-item img:hover {
  transform: scale(1.05);
}

.invisible-item {
  visibility: hidden;
}