.label-rectangle {
    display: block;
    background-color: #a7e4e1;
    border: 0.5vw solid black;
    border-radius: 1vw;
    margin-top: 40vh;
    margin-left: 51.41vw;
    width: 38.91vw;  
}

.label-text {
    color: red;
    font-family: 'Fredoka', sans-serif;
    font-weight: 600;
    text-align: center;
    font-size: 5vw;
    padding: 2vh 0;
}