/* ===== Horizonal multiple choice with pictures ===== */
.horizontal-mc-picture-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: var(--screen-width);
  height: var(--screen-height);
}

.picture-btn-container-2 {
  display: flex;
  justify-content: center;
  gap: calc(var(--screen-width) * 0.015);
  margin-top: calc(var(--screen-height) * 0.18);
}

.two-options-container {
  display: flex;
  justify-content: center;
  gap: calc(var(--screen-width) * 0.1);
  margin-top: calc(var(--screen-height) * 0.18);
}

.mcp-button-img-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: calc(var(--screen-height) * 0.03);
}

/* ===== Button style ===== */
.picture-btn-2 {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: calc(var(--screen-width) * 0.28);
  padding: 0 calc(var(--screen-width) * 0.01);
  border-radius: calc(var(--screen-width) * 0.04);
  border: calc(var(--screen-width) * 0.005) solid black;
  background-color: aliceblue;
  font-family: "Balsamiq Sans";
  font-weight: 500;
  font-size: calc(var(--screen-width) * 0.023);
  text-align: center;
  transition: background-color 0.3s ease;
}

.picture-btn-2:hover {
  background-color: #a7e4e1;
}

/* ===== Image container style ===== */
.picture-container-2 {
  width: calc(var(--screen-width) * 0.28);
  height: calc(var(--screen-height) * 0.31);
  display: flex;
  justify-content: center;
  align-items: center;
  object-fit: contain;
}

.picture-container-2 img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.mcp-button-img-container:hover .picture-btn-2 {
  background-color: #a7e4e1;
}

.correct-btn {
  background-color: #a7e4e1;
}

.inactive-btn {
  pointer-events: none;
  cursor: default;
  opacity: 0.8;
}

.inactive-btn:hover {
  background-color: inherit;
}

