@import url('https://fonts.googleapis.com/css2?family=Balsamiq+Sans&display=swap');

:root {
  --base-width: 1000;
  --base-height: 750;
}

.match {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: calc(var(--screen-height) * (20 / var(--base-height)));
  padding-top: calc(var(--screen-height) * (150 / var(--base-height)));
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  overflow: hidden;
  font-family: 'Balsamiq Sans', cursive;
}

.match__draggables {
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
  gap: calc(var(--screen-width) * (20 / var(--base-width)));
  margin-bottom: calc(var(--screen-height) * (30 / var(--base-height)));
  width: 100%;
  overflow: hidden;
}

.match__droppables {
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
  gap: calc(var(--screen-width) * (20 / var(--base-width)));
  width: 100%;
  overflow-x: auto;
}

.match__pair {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: calc(var(--screen-height) * (10 / var(--base-height)));
}

.match__image {
  width: calc(var(--screen-width) * (200 / var(--base-width)));
  height: calc(var(--screen-height) * (200 / var(--base-height)));
  object-fit: contain;
}

.match__item {
  width: calc(var(--screen-width) * (200 / var(--base-width)));
  height: calc(var(--screen-height) * (80 / var(--base-height)));
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #A7E4E1;
  border: calc(var(--screen-width) * (4 / var(--base-width))) solid black;
  border-radius: calc(var(--screen-width) * (5 / var(--base-width)));
  cursor: move;
  user-select: none;
  font-size: calc(var(--screen-width) * (60 / var(--base-width)));
  color: #000000;
  font-family: 'Balsamiq Sans', cursive;
  font-weight: 900;
}

.match__item--dragging {
  opacity: 0.5;
}

.match__item--droppable {
  background-color: white;
  cursor: default;
}

.match__pair--no-sprite .match__item--droppable {
  background-color: #A7E4E1;
  margin-bottom: calc(var(--screen-height) * (90 / var(--base-height)));
}

.match__item--placeholder {
  visibility: hidden;
}

.match__wrapper {
  position: relative;
  z-index: 1;
  touch-action: none;
}

.match__overlay {
  z-index: 1000;
}

.match__overlay .match__item {
  transform: scale(1.05);
  box-shadow: 0 calc(var(--screen-height) * (2 / var(--base-height))) calc(var(--screen-height) * (5 / var(--base-height))) rgba(0,0,0,0.2);
}

.match__placeholder-text {
  color: #8B4513;
  font-size: calc(var(--screen-width) * (14 / var(--base-width)));
}

.match__droppables::-webkit-scrollbar {
  height: calc(var(--screen-height) * (8 / var(--base-height)));
}

.match__droppables::-webkit-scrollbar-thumb {
  background-color: #A7E4E1;
  border-radius: calc(var(--screen-width) * (4 / var(--base-width)));
}

.match__droppables::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}
