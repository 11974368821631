/* .loading-page {
    margin: 0;
    padding: 0;
    background: url("components/Lesson1/images/backgroundImages/BG_Default.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    min-height: 100vh;
}

.loading-page2 {
    margin: 0;
    padding: 0;
    background: url("components/Lesson1/images/backgroundImages/BG_Volcano.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    min-height: 100vh;

} */

:root {
    --screen-height: 0;
    --screen-width: 0; 
}

/* Disables horizontal scrollbar */
/* TODO: figure out how to set backgrounds dynamically */
body { 
    background: url("./assets/backgrounds/BG_Border_Default.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
.screen-space {
    display: flex;  
    width: 99vw; 
    height: 99vh; 
}
.fixed-ratio {
    width: var(--screen-width);
    height: var(--screen-height);
    display: flex;  
    outline:  5px solid black;  
    margin: auto auto;
    position: relative;
    overflow: clip;
}
  
mark {
    color: #FFC702;
    background-color: transparent;
}