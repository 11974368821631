/* ===== new vocabulary post it ===== */

.pencil {
    position: absolute;
    width: calc(var(--screen-width) * 0.24);
    margin-top: calc(var(--screen-height) * 0.13);
    margin-left: calc(var(--screen-width) * 0.72);
}

.post-it-container {
    display: flex;
    position: absolute;
    justify-content: center;
    align-items: center;
    width: calc(var(--screen-width) * 0.37);
    height: calc(var(--screen-height) * 0.61);
    margin-top: calc(var(--screen-height) * 0.13);
    margin-left: calc(var(--screen-width) * 0.47);
}

.post-it-container img {
    max-width: 100%;
    max-height: 100%;
}

.speech-icon {
    border-radius: calc(var(--screen-width) * 0.035);
    border-width: calc(var(--screen-width) * 0.005);
    width: calc(var(--screen-width) * 0.11);
    position: absolute;
}

.speech-icon:after {
    display: block;
    padding-bottom: 100%;
}

.vocab-text {
    position: absolute;
    color: red;
    font-family: 'Balsamiq Sans', sans-serif;
    font-weight: 600;
    object-fit: contain;
}

.top-text {
    font-size: calc(var(--screen-width) * 0.04);
    top: calc(var(--screen-height) * 0.08);
}

/* number of syllable */
.bottom-text-1 { 
    font-size: calc(var(--screen-width) * 0.025);
    bottom: calc(var(--screen-height) * 0.13);
}

/* the break down pronunciation */
.bottom-text-2 {
    font-size: calc(var(--screen-width) * 0.025);
    bottom: calc(var(--screen-height) * 0.09);
}

.postit-record-btn {
    position: absolute;
    width: calc(var(--screen-width) * 0.08);
    top: calc(var(--screen-height) * 0.12);
}

.record-text-container {
    display: flex;
    position: absolute;
    align-items: center;
    width: calc(var(--screen-width) * 0.25);
    height: calc(var(--screen-height) * 0.35);
    top: calc(var(--screen-height) * 0.14);
    left: calc(var(--screen-width) * 0.065);
}

.record-text {
    color: black;
    font-family: 'Balsamiq Sans', sans-serif;
    font-weight: 500;
    object-fit: contain;
    font-size: calc(var(--screen-width) * 0.03);
    text-align: center;
}

.checkbox {
    display: flex;
    position: relative;
    height: calc(var(--screen-height) * 0.095); 
}





