.character-btn { 
    background-color: aliceblue;
    width: calc(var(--screen-width) * 0.5);
    font-family: 'Balsamiq Sans';
    font-weight: 600;
    font-size: calc(var(--screen-width) * 0.02);
    text-overflow: ellipsis;
    border: none;

    &:hover {
        background-color: #A7E4E1;
        transform: scale(1.05)
    }
}

.character-btn img {
    width: calc(var(--screen-width) * 0.2);
    height: calc(var(--screen-height) * 0.3);
    justify-items: center;
    align-items: center;
    padding-bottom: calc(var(--screen-height) * 0.01);
}